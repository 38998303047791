import axios from 'axios';
import { ssoCookieChanged, initSsoUid } from './cookie';
// import { initSsoUid } from './cookie';
import { isInnerIP } from './ip';

const instance = axios.create();

let doingReSetStorage = false;

function doReSetStorage(onSuccess, onException, token) {
    if (doingReSetStorage) {
        return;
    }
    doingReSetStorage = true;
    let url = '/knowledge-api/sso/auth/clientAuth?setJwtCookie=true';
    if (token != null) {
        url = url + '&token=' + token;
    }
    // 本地调试时需要
    let headers = {};
    if (process.env.NODE_ENV === 'development') {
        headers.xhost = 'localhost';
    }
    console.log('auth.js');
    instance.get(url, {headers: headers})
        .then((res) => {
            localStorage.clear();
            localStorage.setItem("_uid", res.data.data.userId);
            sessionStorage.setItem('_uid',res.data.data.userId);
            localStorage.setItem('_mainId', res.data.data.mainId);
            // localStorage.setItem("accountType", res.data.data.accountType);
            localStorage.setItem("roleId", res.data.data.roleId);
            localStorage.setItem("username", res.data.data.username);
            localStorage.setItem("profilePhoto", res.data.data.profilePhoto);
            localStorage.setItem("knowledgeNickName", res.data.data.realName);
            localStorage.setItem("realName", res.data.data.realName);
              // localStorage.setItem("realName", res.data.data.knowledgeNickName && res.data.data.knowledgeNickName !== '' ? res.data.data.knowledgeNickName : res.data.data.realName);
            sessionStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);
            localStorage.setItem('rolePrivileges', JSON.stringify(res.data.data.rolePrivileges))
            localStorage.setItem("phone", res.data.data.phone);
            localStorage.setItem("accountSource", res.data.data.source);
            console.log('accountSource-存储');
            localStorage.setItem("knowledgeUserName", res.data.data.knowledgeUserName);
            sessionStorage.setItem('isFirstGoIndex', 1);
            localStorage.setItem('companyDtoList', res.data.data.companyDtoList.length);
            localStorage.setItem('haveKnowledgePermission', res.data.data.haveKnowledgePermission);
            localStorage.setItem('haveWorkOrderPermission', res.data.data.haveWorkOrderPermission);
            localStorage.setItem('accountSourceName', res.data.data.companyName);
            localStorage.setItem('memberInDepartDto', JSON.stringify(res.data.data.memberInDepartDto) || '[]');
            sessionStorage.setItem('memberInDepartDto', JSON.stringify(res.data.data.memberInDepartDto) || '[]')
            localStorage.setItem('_corpId', res.data.data.corpId);
            sessionStorage.setItem('_corpId',res.data.data.corpId);
            sessionStorage.setItem('userId', res.data.data.userId)
            localStorage.setItem('haveManagerPermission', res.data.data.haveManagerPermission);
            localStorage.setItem("authStatus",  res.data.data.authStatus);
            localStorage.setItem('_token',token)
            sessionStorage.setItem('_token', token)
            sessionStorage.setItem('_groupIds',JSON.stringify(res.data.data.groupIds));
            if (localStorage.getItem('inviteDeptData') && JSON.parse(localStorage.getItem('inviteDeptData')).companyName && JSON.parse(localStorage.getItem('inviteDeptData')).corpId) {
                localStorage.setItem('accountSourceName', JSON.parse(localStorage.getItem('inviteDeptData')).companyName);
                localStorage.setItem('_corpId',JSON.parse(localStorage.getItem('inviteDeptData')).corpId)
            }
            sessionStorage.setItem("userInfo", JSON.stringify(res.data.data))
            initSsoUid();
            doingReSetStorage = false;
            findManageUser()
            // instance.get("/portal-api/sso/auth?token=" + token)
            // // 获取当前登录企业用户是否有访问管理后台权限（GET）
            // instance.get(url, { headers: headers }).then((res) => { 

            // }).catch((err) => {
            //     doingReSetStorage = false;
            //     console.info('do auth exception', err);
            //     if (onException) {
            //         onException(err);
            //     }
            // });
            if (onSuccess) {
                onSuccess(res);
            }
        }).catch((err) => {
            doingReSetStorage = false;
            console.info('do auth exception', err);
            if (onException) {
                onException(err);
            }
        });
}

function findManageUser() {
    let corpId = localStorage.getItem("_corpId")
    let clientUserId = localStorage.getItem("_uid");
    instance.get(`/knowledge-api/sso/auth/find-manage-user?corpId=${corpId}&clientUserId=${clientUserId}`).then(res =>{
        if(res.data.code == '0' && res.data.data) {
            localStorage.setItem("clientUserId", res.data.data.id)
        }
    })
}

function doCheckAndRestStorageInfo() {
    if (isInnerIP) {
        return;
    }
    if (ssoCookieChanged()) {
        doReSetStorage();
    }
}

export { doCheckAndRestStorageInfo, doReSetStorage }