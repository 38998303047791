import { requestUrl } from "@/http/requestUrl";
import axios from 'axios';
const instance = axios.create();
export function getBotInfo(callBack){
    let url = requestUrl.knowledge.getBotBindInfo + "?userId=" + localStorage.getItem("_uid");
    instance.get(url).then(res => {
        if (res.data.code === "0"){
            let resData = res.data.data;
            sessionStorage.setItem('_agentId',resData.agentId);
            sessionStorage.setItem('_corpId',resData.corpId);
            sessionStorage.setItem('_bindCode',resData.bindCode);
            sessionStorage.setItem('_apiKey',resData.apiKey);
            sessionStorage.setItem('_wechatType',resData.type);
            sessionStorage.setItem('_userId',resData.userId || resData.openId);
            sessionStorage.setItem('_userMemberId',resData.userMemberId);
            sessionStorage.setItem('_srcUserId',resData.memberUserId)
            sessionStorage.setItem(
                "_userDeptId",
                resData.userDeptId
            );
            sessionStorage.setItem(
                "_userDeptName",
                resData.userDeptName
            );
            sessionStorage.setItem('_sourceId',resData.sourceId);
            sessionStorage.setItem('_secret',resData.secret)
            if (resData.passedAgents && resData.passedAgents.length > 0){
                sessionStorage.setItem('passedAgents',JSON.stringify(resData.passedAgents))
            } else {
                sessionStorage.setItem('passedAgents',JSON.stringify([]))
            }
            if (callBack){
                callBack(resData);
            }
        }
    }).catch(() => {
        if (callBack){
            callBack();
        }
    })
}