import { render, staticRenderFns } from "./MainNav.vue?vue&type=template&id=04f6f3b8&scoped=true"
import script from "./MainNav.vue?vue&type=script&lang=js"
export * from "./MainNav.vue?vue&type=script&lang=js"
import style0 from "./MainNav.vue?vue&type=style&index=0&id=04f6f3b8&prod&lang=less"
import style1 from "./MainNav.vue?vue&type=style&index=1&id=04f6f3b8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f6f3b8",
  null
  
)

export default component.exports