const requestUrl = {
    upload: { //上传
        uploadCallback: '/knowledge-api/knowledge/upload-callback', // POST 创建二维码接口
        checkCapacity: '/knowledge-api/knowledge/check/capacity', // POST 校验容量限制
        getCapacity: "/knowledge-api/knowledge/get/capacity", // POST 获取容量信息
        getCount: "/knowledge-api/aigc-record/count", // POST 获取次数信息
        getKnowledgeSummaryNoticeList: '/knowledge-api/knowledge/handle/record/list', // 查询摘要记录 GET
        viewKnowledge: '/knowledge-api/knowledge/handle/record/view/', // 浏览摘要记录 PUT
        markKnowledgeSummary: '/knowledge-api/knowledge/handle/record/mark', // 标记摘要记录 POST
        queryUserUseAiCollaboration:"/knowledge-api/aigc-record/queryUserUseAiCollaboration", // 是否使用过AI协作能力 GET
    },
    knowledge: { // 知识管理
        searchKnowledge: '/knowledge-api/knowledge/search', // POST 首页知识列表搜索
        relearnKnowledge:'/knowledge-api/knowledge/rebuild-index/', // POST 知识重新学习
        deleteKnowledge: '/knowledge-api/knowledge', // DELETE 删除知识
        changeKnowledge: '/knowledge-api/knowledge/', // PUT 更新模版
        getAigcCmdList: '/knowledge-api/aigc-cmd/list/', // GET 获取场景指令集
        aiLearning: '/knowledge-api/knowledge/ai-learning-progress', //POST 获取AI学习进度
        collectKnowledge: '/knowledge-api/knowledge/collect', // PUT 收藏或者取消收藏知识
        getBotBindInfo: '/api-open/bind/knowledge/auth', // GET 获取机器人信息
        getTemporaryCertificate: '/knowledge-api/knowledge/getTemporaryCertificate', // 获取文件临时访问链接(POST)
        htmlTextDeal: '/knowledge-api/knowledge/htmlTextDeal', //POST 处理编辑器中插入图片和附件的oss访问地址为临时地址
        getUserHistoryCmd:'/knowledge-api/aigc-record/getUserHistoryCmd', // 获取用户历史指令（GET）
        getKnowledgeBaseById:"/knowledge-api/knowledgeBase/selectKnowledgeBaseById",
        getKnowledgeBaseDetail: "/knowledge-api/knowledge/detail/", // 获取关联知识详情
        knowledgeBaseTreeLazy:"/knowledge-api/knowledgeBase/selectKnowledgeBaseStructureTree",
        getFolderAndFile:"/knowledge-api/knowledgeBase/selectTreeFolderAndFile",
        tagTree:"/tag-api/tag/selectTree", //GET 查询子标签
        clientSelectKnowledgeBaseStructureTree: '/knowledge-api/knowledgeBase/clientSelectKnowledgeBaseStructureTree',
        clientSelectTreeFolderAndFile: '/knowledge-api/knowledgeBase/clientSelectTreeFolderAndFile',
        loginWorkOrder: "/sso/auth-token-knowledge-workorder"
    },
    aigc: {
        getAiCreateContent: '/aigc/chatgpt/stream-completions', // POST 回复内容推送，EventSource流推送
        getStream: '/aigc/chatgpt/stream', // GET 订阅
        streamCompletions: '/aigc/chatgpt/stream-completions/v2', // POST 发送生成流请求
        getStreamv2: '/aigc/chatgpt/stream/v2', // GET 订阅V2
    },
    newAuth: {
        creatQrcodes: '/sso/pwx-auth/qrcodes',// 创建二维码接口
        pwxAuth: '/sso/pwx-auth/qrcodes', // GET 轮询检测状态接口
        pwxAuthMobile: '/sso/pwx-auth/mobile/', // PUT 更新手机号接口
        clientRegisterAndLogin: `/api/register/registerAndLogin`, // POST 客户端注册并登录账号
        tokenToAuthInfo: `/knowledge-api/sso/auth/clientAuth`, // GET 通过token获取用户信息
        tokenToAuthInfoV2: "/sso/mock-token-cookie",
        clientLogin: `/portal-api/register/clientLogin`, // POST 客户端登录
        clientLoginOut: `/knowledge-api/sso/auth/client/sign-out`, // DELETE 退出登录
        register: `/api/register`, // POST 客户端注册
        getCurrentIp: `https://www.askbot.cn/ext-api/ip`, // GET 获取当前ip
        resetPassword: `/api/account/forgotPassword?userName`, // POST 重置密码
        checkphone: `/api/register/check`, // GET 检查电话号码
        sendcode: `/api/code/sms/send`, // POST 发送验证码
        checkCode: '/api/code/sms/check',// POST 检测验证码是否有效
        findManageUser: '/knowledge-api/sso/auth/find-manage-user', // 根据当前登录人的uid查询对应后台关联的userid
    },
    template: {
        createTemplate: "/knowledge-api/knowledge-template", // POST 创建模板
        getTemplateInfo: "/knowledge-api/knowledge-template/", // GET {id} 获取模板详情
        updateTemplate: "/knowledge-api/knowledge-template/", // PUT {id} 更新模板
        deleteTemplate: "/knowledge-api/knowledge-template/", // DELETE {id} 删除模板
        getTemplateList: "/knowledge-api/knowledge-template/page", // GET 获取模板列表
        sortTemplate: "/knowledge-api/knowledge-template/sort", // POST 模板排序
    },
    preview: {
        previewUrl: '/knowledge-api/knowledge/getTemporaryCertificate',
    },
    recycleBin: {
        recycleBinList: "/knowledge-api/recycle-bin/search",
        recycleBinRestore: "/knowledge-api/recycle-bin/restore",
        deleteRecycleBin: "/knowledge-api/recycle-bin"
    },
    pay: { // 付费
        selectVersionUpgrades: '/knowledge-api/pay/selectVersionUpgrades', // 获取会员版本/流量包可购买版本信息 GET
        selectFlowPrice: '/knowledge-api/pay/selectFlowPrice', // 查询流量包价格（GET）
        selectVipPrice:'/knowledge-api/pay/selectVipPrice', // 查询各版本会员价格（GET）
        getWxPayCodeUrl: '/knowledge-api/pay/getWxPayCodeUrl', // 根据用户购买产品生成微信支付二维码（POST）
        selectUserBuyAbility: '/knowledge-api/pay/selectUserBuyAbility', // 查询用户会员信息、流量包、空间容量（GET）
        useConversionCode: '/knowledge-api/pay/useConversionCode', // 使用对话码（GET）
        selectCapacityPrice:'/knowledge-api/pay/selectCapacityPrice', // 查询容量包价格（GET）
    },
    invite: { // 邀请注册
        getInfo: '/knowledge-api/invite/info', // GET 生成邀请信息
        getInviteList: '/knowledge-api/user/handle/record/list', // 获取赠送VIP记录 GET
        markInvite: '/knowledge-api/user/handle/record/mark', // 标记赠送VIP记录 POST
        saveInvite: '/knowledge-api/invite/record/save', // 保存邀请记录（用于测试） POST
        getRealUserName: '/api/account/find-real-name-by-phone', // 通过手机号获取用户姓名
    },
    company: { // 兼容企业版
        clientCreateCompany: '/knowledge-api/company/clientCreateCompany', // 客户端创建企业（GET）
        queryMemberCompany: '/knowledge-api/company/queryMemberCompany', // 查询用户的所有开通知识管理的企业（GET）
        // switchCompany: '/sso/auth-token-knowledge', // 客户端切换企业（POST）
        getToken: '/knowledge-api/sso/auth/clientAuth', // 获取用户授权信息（POST）
        inviteJoinCompany: '/knowledge-api/company/inviteJoinCompany', //使用邀请码/邀请链接加入企业(POST)
        getUserPrivilege: '/portal-api/account/appoint', // 获取当前登录企业用户是否有访问管理后台权限（GET）
        submitFlowApply: '/knowledge-api/company/submitApply', // 向企业提交流量/容量扩容申请（POST）
        getAppoint: '/portal-api/account/appoint', // 获取当前登录企业用户是否有访问管理后台权限（GET）
        getCompanyToken: '/sso/auth-token-knowledge', // 客户端切换企业（POST）
        getCompanyWebToken: '/sso/auth-token-web-knowledge', // web客户端切换企业（POST）
        getFlowAndCapacityList: '/knowledge-api/company/flowAndCapacity/record/list', // 获取流量/容量审批记录（GET）
        markFlowAndCapacity: '/knowledge-api/company/flowAndCapacity/record/mark', // 标记获取流量/容量审批记录（POST）
        getJoinCompanyList: '/knowledge-api/company/joinCompany/record/list', // 获取加入企业审批记录（GET）
        markJoinCompany: '/knowledge-api/company/joinCompany/record/mark', // 标记获取加入企业审批记录（POST）
        substitution: "/portal-api/invite/substitution", // 邀请码置换邀请链接 GET
        getInviteDepartment: "/portal-api/invite/getInviteDepartment", // 获取邀请部门信息  GET
        inviteCodeRegisterAndLogin: "/portal-api/register/inviteCodeRegisterAndLogin", // 邀请链接客户端用户注册（POST）
        wxInviteJoinCompany: '/knowledge-api/company/wxInviteJoinCompany', // 微信扫码登录后邀请加入企业（POST）
        userBigModelActivate: "/knowledge-api/company/userBigModelActivate", // 用户大模型能力激活（GET）
    },
    agentConfig:{
        getWechat:"/api/account/third-wechat-corp-info",
        agentConfigNew:"/api/account/askbot-wechat-agent-config"
    },
    //知识评价相关接口
    comment:{
        commentList:"/knowledge-api/knowledge/comment/list", //post
        saveComment:"/knowledge-api/knowledge/comment", //POST
        removeComment:"/knowledge-api/knowledge/comment/remove/", //DELETE
        replyComment:"/knowledge-api/knowledge/comment/reply", //POST
        likeComment:"/knowledge-api/knowledge/comment/like/", //PUT
        likeKnowledge:"/knowledge-api/knowledge/like/", //PUT
        likeKnowledgeList:"/knowledge-api/knowledge/like/",//GET
        updateReply:"/knowledge-api/knowledge/comment/update",
        publicComment:"/knowledge-api/knowledge/public/comment/list",
        publicLike:"/knowledge-api/knowledge/public/like/"

    },
    department:{
        newSearchUser:"/portal-api/department/search-member-v2" //搜索员工接口
    },
    share:{
        within:"/knowledge-api/knowledge/share/within/url",
        withOutside:"/knowledge-api/knowledge/share/outside/url",
        shareConfig:"/knowledge-api/knowledge/share/config/",
        viewWithInUrl:"/knowledge-api/knowledge/share/view/url",
        viewOutsideUrl:"/knowledge-api/knowledge/share/view/anonymous-url",
        canShare:"/knowledge-api/knowledge/share/config/can-share/",
    },
    withMe:{
        withMeList:"/knowledge-api/knowledge/related-to-me/list",
        viewDoc:'/knowledge-api/knowledge/related-to-me/view/',
        withMeNewCount:"/knowledge-api/knowledge/related-to-me/new-count",
        removeDoc:"/knowledge-api/knowledge/related-to-me/remove/",
        collectKnowledge:"/knowledge-api/knowledge/related-to-me/favorite/"
    },
    search: {
        getHistoryList: "/api-open/request/record/list",
        clearHistory: "/api-open/request/record/clear",
        removeHistory: "/api-open/request/record/delete/",
        getSessionList: "/api-open/session/record/page",
        saveSessionDialog: "/api-open/session/record/save",
        saveOrUpdateSessionDialog: "/api-open/session/record/save-or-update",
        saveSessionName: "/api-open/session/record/update",
        deleteSessionDialog: "/api-open/session/record/delete",
        sessionIdGetDetail: "/api-open/session/record/session",
    },
    news:{
        pinToTopNews:"/knowledge-api/news-announcement/pin-to-top/search",
        directory:"/knowledge-api/news-announcement/directory/search",
        newsAnnouncement:"/knowledge-api/news-announcement/search",
        newsView:"/knowledge-api/news-announcement/view/",
        newsKnowledgeView:"/knowledge-api/news-announcement/knowledge/"
    },
    textWatermark:{
        getTextWatermark:"/knowledge-api/knowledgeBase/getPermissionInfo"
    },
    intelligent:{
        setDefault:"/api/useragent/markDefault",
        setTop:"/api/useragent/setTop",
        getList:"/api/useragent/get"
    },
    docVersion:{
        selectDocumentVersionByDocId: "/knowledge-api/docVersion/selectDocumentVersionByDocId", // 查询文档的历史版本（GET）
        useDocumentVersionByDocId: "/knowledge-api/docVersion/useDocumentVersionByDocId", // 使用文档的历史版本（GET）
        selectDocumentVersionHaveNew: "/knowledge-api/docVersion/selectDocumentVersionHaveNew", // 查询文档是否有新版本（GET）
        doubleNameCheck: "/knowledge-api/knowledgeBase/doubleNameCheck", // 校验本次上传的文件是否存在重名（POST）
    }
}

export { requestUrl }
